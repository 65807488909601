import React, { useRef, useEffect } from 'react';
import '../styles/ConversationLog.css';

interface ConversationLogProps {
  messages: string[];
}

const ConversationLog: React.FC<ConversationLogProps> = ({ messages }) => {
  const logRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (logRef.current) {
      logRef.current.scrollTop = logRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="dashboard-content" id="conversation-log" ref={logRef}>
      {messages.map((message, index) => (
        <div key={index} className={message.startsWith('> ') ? 'user-question' : 'ai-answer'}>
          {message}
        </div>
      ))}
    </div>
  );
};

export default ConversationLog;

