import '../styles/Dashboard.css';

import React, { useEffect, useState } from 'react';

import ConversationLog from './ConversationLog';
import VoiceInput from './VoiceInput';
import { handleVoiceQuery } from '../utils/voiceUtils';

const Dashboard: React.FC = () => {
  const [conversationLog, setConversationLog] = useState<string[]>([]);
  const [status, setStatus] = useState<string>('Ready to listen');

  const addToConversationLog = (message: string, isUser: boolean) => {
    setConversationLog(prev => [...prev, `${isUser ? '> ' : ''}${message}`]);
  };

  const onVoiceInput = async (query: string) => {
    addToConversationLog(query, true);
    setStatus('Processing...');
    
    try {
      let response = await handleVoiceQuery(query);
      response = response.replace(/<style([\s\S]*?)<\/style>/gi, '')
                            .replace(/<script([\s\S]*?)<\/script>/gi, '')
                            .replace(/<\/?div[^>]*>/gi, '\n')
                            .replace(/<\/?li[^>]*>/gi, '  *  ')
                            .replace(/<\/?ul[^>]*>/gi, '')
                            .replace(/<\/?p[^>]*>/gi, '\n')
                            .replace(/<br\s*[\/]?>/gi, "\n")
                            .replace(/<[^>]+>/ig, '')
                            .replace(/tokens\s*[:：]\s*\S+/g, '')
                            .trim();
      addToConversationLog(response, false);
    } catch (error) {
      addToConversationLog('Sorry, an error occurred while processing the inquiry.', false);
    }
    
    setStatus('Ready to listen');
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const href = urlParams.get('href'); // Get the value of 'href' parameter
    if (href) {
      handleVoiceQuery(href).then(response => {
        response = response.replace(/<style([\s\S]*?)<\/style>/gi, '')
                              .replace(/<script([\s\S]*?)<\/script>/gi, '')
                              .replace(/<\/?div[^>]*>/gi, '\n')
                              .replace(/<\/?li[^>]*>/gi, '  *  ')
                              .replace(/<\/?ul[^>]*>/gi, '')
                              .replace(/<\/?p[^>]*>/gi, '\n')
                              .replace(/<br\s*[\/]?>/gi, "\n")
                              .replace(/<[^>]+>/ig, '')
                              .replace(/tokens\s*[:：]\s*\S+/g, '')
                              .trim();
        addToConversationLog(response, false);
      }).catch(err => {
        addToConversationLog('Sorry, an error occurred while processing the inquiry.', false);
      })
    }
  }, []);

  return (
    <div className="dashboard-card">
      <div className="card-header">
        <h2 className='text-gradient'>Ask Me Anything</h2>
      </div>
      <ConversationLog messages={conversationLog} />
      <VoiceInput onVoiceInput={onVoiceInput} status={status} setStatus={setStatus} />
    </div>
  );
};

export default Dashboard;

