import '../styles/Navbar.css'; // Assuming you already have this file

import { ArrowRight, DollarSign, Menu } from 'lucide-react'; // Importing Lucide icons
import React, { useState } from 'react';

const imageUrl = new URL('/assets/images/logox.png', import.meta.url).href;

const Navbar: React.FC = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  const toggleMenu = () => {
    setIsMenuActive((prevState) => !prevState);
  };

  return (
    <div className="navbar">
      <div className="logo" onClick={() => window.open('https://silentbase.xyz/', '_blank')}>
        <img
          src={imageUrl}
          alt="Silent Base logo"
          width="40"
          height="40"
        />
        <span className="text-gradient">Silent Base</span>
      </div>

      <div className="hamburger" onClick={toggleMenu}>
        {/* Replacing Font Awesome icon with Lucide Menu icon */}
        <Menu className="text-gradient" />
      </div>

      <div className={`menu ${isMenuActive ? 'active' : ''}`}>
        <div className="dropdown">
          <a href="#">Products <span className="badge">4</span></a>
          <div className="dropdown-content">
            <a href="https://app.silentbase.xyz/" target='_blank'>Orderbook</a>
            <a href="https://app.silentbase.xyz/bridge" target='_blank'>Bridge</a>
            <a href="https://app.silentbase.xyz/staking" target='_blank'>Staking</a>
            <a href="https://ai.silentbase.xyz">AI Generative</a>
          </div>
        </div>
        <a href="https://ai.silentbase.xyz?href=about-us">About Us</a>
        <div className="dropdown">
          <a href="#">Docs <span className="badge">5</span></a>
          <div className="dropdown-content">
            <a href="https://silentbase.xyz/files/whitepaper_v2.pdf" target="_blank" rel="noopener noreferrer">Whitepaper</a>
            <a href="https://docs.silentbase.xyz/" target="_blank" rel="noopener noreferrer">Documentation</a>
          </div>
        </div>
        <a href="https://silentbase.xyz/#community" target='_blank'>Community</a>
      </div>

      <div className="actions">
        <button
          className="btn buy-dao"
          onClick={() => window.open('https://exchange.silentbase.xyz/', '_blank')}
        >
          {/* Replacing Font Awesome with Lucide DollarSign icon */}
          Crypto Swap
        </button>
      </div>
    </div>
  );
};

export default Navbar;
