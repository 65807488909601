import { EventSourcePolyfill } from 'event-source-polyfill';

const CONFIG = {
  BASE_URL: 'https://apigen.silentbase.xyz',
  TIMEOUT: 30000,
  MAX_RETRIES: 3,
};

const BRAIN = {
  "company": {
    "name": "Silent Base",
    "overview": "Silent Base is a pioneering decentralized finance (DeFi) platform built on Ethereum's Layer 2 network, dedicated to transforming the financial ecosystem through innovation, privacy, and community empowerment."
  },
  "vision": {
    "transparent_and_fair": "We believe in a financial future that is transparent and fair, where all transactions are visible and execution remains impartial.",
    "secure_and_private": "We prioritize security and privacy, ensuring the protection of our users' data with cutting-edge cryptographic techniques.",
    "accessible_to_everyone": "We aim to make decentralized finance accessible to everyone, ensuring that no one is excluded from the financial ecosystem.",
    "driven_by_community_participation": "Our platform is driven by community participation, where token holders directly influence the development of the ecosystem."
  },
  "what_we_offer": {
    "decentralized_trading": "A fully transparent order book system that ensures fair trade execution, providing users with a trustless trading environment.",
    "staking_opportunities": "Earn passive income by participating in our staking program, where users can stake their tokens and receive rewards.",
    "cross_chain_bridging": "Seamlessly transfer assets across multiple blockchain networks, enabling interoperability and liquidity between different blockchain ecosystems.",
    "advanced_privacy_protection": "We utilize advanced cryptographic techniques like zero-knowledge proofs (ZKPs) to safeguard user data and ensure privacy."
  },
  "core_principles": {
    "decentralization": "We believe in removing intermediaries and putting control back in the hands of users, giving them full control over their assets and decisions.",
    "privacy": "We prioritize user privacy and protect information through state-of-the-art encryption methods and anonymous interactions.",
    "efficiency": "Leveraging Ethereum's Layer 2 technology, we provide fast, scalable, and low-cost transactions that make decentralized finance more accessible.",
    "community_governance": "Silent Base is a community-driven project where token holders have the power to influence platform decisions and help shape its future."
  },
  "native_token": {
    "name": "Silent Base Token (SBT)",
    "description": "The Silent Base Token (SBT) is the lifeblood of our ecosystem. It serves as the utility token for various platform features and incentives.",
    "benefits": [
      "Discounted transaction fees",
      "Cross-chain compatibility",
      "Staking rewards",
      "Long-term value appreciation"
    ]
  },
  "contact_information": {
    "matrix": "@silentbase:matrix.org",
    "website": "https://silentbase.xyz"
  },
  "contract_address_inquiry": {
    "question": "What is the contract address of Silent Base?",
    "answer": "For the contract address, please visit our official website at https://silentbase.xyz. We recommend checking directly from there for the most accurate and up-to-date information."
  },
  "scope_list_domain": {
    "silentbase.xyz": "Landing page, showcasing the core features, vision, and platform overview of Silent Base.",
    "exchange.silentbase.xyz": "Off-chain Exchange Instant, providing users with a seamless and immediate trading experience outside of blockchain delays.",
    "app.silentbase.xyz": "Orderbook, Staking, Bridge, allowing users to interact with decentralized orderbooks, stake tokens, and bridge assets across different networks.",
    "ai.silentbase.xyz": "AI Assistant, providing intelligent assistance and support to users, guiding them through platform features and DeFi-related queries."
  }
}



export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export async function handleVoiceQuery(query: string): Promise<string> {
  let uid = localStorage.getItem("uid");
  const lastDeleteTime = localStorage.getItem("lastDeleteTime");
  const currentTime = new Date().getTime();
  if (!lastDeleteTime) {
    localStorage.setItem("lastDeleteTime", currentTime.toString());
    localStorage.removeItem("uid");
  } else {
    if (lastDeleteTime && currentTime - parseInt(lastDeleteTime) >= 240000) {
      localStorage.removeItem("uid");
    }
  }
  if (!uid) {
    uid = uuid();
    localStorage.setItem("lastDeleteTime", currentTime.toString());
    localStorage.setItem("uid", uid);
    const initBrain = JSON.stringify(BRAIN);
    new Promise((resolve, reject) => {
      let retryCount = 0;
      let responseText = '';

      const sendQuery = () => {
        if ((window as any).activeEventSource) {
          (window as any).activeEventSource.close();
        }

        const eventSource = new EventSourcePolyfill(`${CONFIG.BASE_URL}/createSse`, {
          headers: { 'uid': uid! }
        });

        (window as any).activeEventSource = eventSource;

        const timeoutId = setTimeout(() => {
          eventSource.close();
          reject(new Error('Request timed out'));
        }, CONFIG.TIMEOUT);

        eventSource.onmessage = (event) => {
          clearTimeout(timeoutId);

          if (event.lastEventId === "[TOKENS]") {
            responseText += event.data;
          }

          if (event.data !== "[DONE]") {
            try {
              const jsonData = JSON.parse(event.data);
              if (jsonData.content && jsonData.content !== 'null') {
                responseText += jsonData.content;
              }
            } catch (parseError) {
              // Silently handle non-JSON data
            }
          }

          if (event.data === "[DONE]") {
            eventSource.close();
            resolve(responseText);
          }
        };

        eventSource.onerror = (error) => {
          clearTimeout(timeoutId);
          reject(error);
        };

        // Add delay before fetch execution
        setTimeout(() => {
          fetch(`${CONFIG.BASE_URL}/chat`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'uid': uid!
            },
            body: JSON.stringify({ msg: initBrain })
          }).catch(error => {
            if (retryCount < CONFIG.MAX_RETRIES) {
              retryCount++;
              setTimeout(sendQuery, 1000 * retryCount);
            } else {
              reject(error);
            }
          });
        }, 1500); // 1.5-second delay before fetch
      };

      sendQuery();
    });
  }

  return new Promise((resolve, reject) => {
    let retryCount = 0;
    let responseText = '';

    const sendQuery = () => {
      if ((window as any).activeEventSource) {
        (window as any).activeEventSource.close();
      }

      const eventSource = new EventSourcePolyfill(`${CONFIG.BASE_URL}/createSse`, {
        headers: { 'uid': uid! }
      });

      (window as any).activeEventSource = eventSource;

      const timeoutId = setTimeout(() => {
        eventSource.close();
        reject(new Error('Request timed out'));
      }, CONFIG.TIMEOUT);

      eventSource.onmessage = (event) => {
        clearTimeout(timeoutId);

        if (event.lastEventId === "[TOKENS]") {
          responseText += event.data;
        }

        if (event.data !== "[DONE]") {
          try {
            const jsonData = JSON.parse(event.data);
            if (jsonData.content && jsonData.content !== 'null') {
              responseText += jsonData.content;
            }
          } catch (parseError) {
            // Silently handle non-JSON data
          }
        }

        if (event.data === "[DONE]") {
          eventSource.close();
          resolve(responseText);
        }
      };

      eventSource.onerror = (error) => {
        clearTimeout(timeoutId);
        reject(error);
      };

      // Add delay before fetch execution
      setTimeout(() => {
        fetch(`${CONFIG.BASE_URL}/chat`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'uid': uid!
          },
          body: JSON.stringify({ msg: query })
        }).catch(error => {
          if (retryCount < CONFIG.MAX_RETRIES) {
            retryCount++;
            setTimeout(sendQuery, 1000 * retryCount);
          } else {
            reject(error);
          }
        });
      }, 1500); // 1.5-second delay before fetch
    };

    sendQuery();
  });
}

