import './App.css';

import React, { useEffect, useRef, useState } from 'react';

import Dashboard from './components/Dashboard';
import Globe from 'react-globe.gl';
import Navbar from './components/Navbar';

const App: React.FC = () => {
  const globeRef = useRef<any>(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    if (globeRef.current) {
      const controls = globeRef.current.controls();
      controls.autoRotate = true;
      controls.autoRotateSpeed = 0.5;
    }
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // Globe.js setup
  const N_PATHS = 10;
  const MAX_POINTS_PER_LINE = 10000;
  const MAX_STEP_DEG = 1;
  const MAX_STEP_ALT = 0.015;

  const gData = [...Array(N_PATHS).keys()].map(() => {
    let lat = (Math.random() - 0.5) * 90;
    let lng = (Math.random() - 0.5) * 360;
    let alt = 0;

    return [[lat, lng, alt], ...[...Array(Math.round(Math.random() * MAX_POINTS_PER_LINE)).keys()].map(() => {
      lat += (Math.random() * 2 - 1) * MAX_STEP_DEG;
      lng += (Math.random() * 2 - 1) * MAX_STEP_DEG;
      alt += (Math.random() * 2 - 1) * MAX_STEP_ALT;
      alt = Math.max(0, alt);

      return [lat, lng, alt];
    })];
  });

  return (
    <><div className='app'><Navbar /></div><div className="app2">

      <div id="globe-container">
        <Globe
          animateIn={true}
          globeImageUrl="//unpkg.com/three-globe/example/img/earth-dark.jpg"
          bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
          backgroundColor="#16181D"
          pathsData={gData}
          pathColor={() => ['rgba(28, 86, 240, 1)', 'rgba(6, 95, 70, 1)']}
          pathDashLength={0.5}
          pathDashGap={2}
          pathDashAnimateTime={2000}
          pathResolution={4}
          pathStroke={0.5}
          width={window.innerWidth}
          height={window.innerHeight}
          ref={globeRef} />
      </div>
      <Dashboard />
      <footer className="footer">
        <p className="text-xs text-gradient">© {new Date().getFullYear()} Silent Base XYZ. All rights reserved.</p>
        <p className="text-xs">
          <a href="https://docs.silentbase.xyz/terms-of-service/silent-base-blockchain-and-ai" target="_blank" className="link-underline">Terms of Services</a>
        </p>
      </footer>
    </div></>
  );
};

export default App;

