import '../styles/VoiceInput.css';

import React, { useEffect, useState } from 'react';

import { Mic } from 'lucide-react';

interface VoiceInputProps {
  onVoiceInput: (query: string) => void;
  status: string;
  setStatus: (status: string) => void;
}

const VoiceInput: React.FC<VoiceInputProps> = ({ onVoiceInput, status, setStatus }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recognition, setRecognition] = useState<any | null>(null);

  useEffect(() => {
    // Memastikan browser mendukung Speech Recognition API
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
      const newRecognition = new SpeechRecognition();
      newRecognition.lang = 'en-US';
      newRecognition.interimResults = false;
      newRecognition.maxAlternatives = 1;

      // Menyimpan instance recognition
      setRecognition(newRecognition);

      // Event handler untuk mulai rekaman
      newRecognition.onstart = () => {
        setIsRecording(true);
        setStatus('Listening...');
      };

      // Event handler untuk hasil rekaman
      newRecognition.onresult = (event: any) => {
        const query = event.results[0][0].transcript.trim();
        onVoiceInput(query);
      };

      // Event handler untuk mengakhiri rekaman
      newRecognition.onend = () => {
        setIsRecording(false);
        setStatus('Ready to listen');
      };

      // Event handler untuk menangani error
      newRecognition.onerror = (event: any) => {
        setIsRecording(false);
        setStatus('Error: ' + event.error);
      };
    } else {
      setStatus('Browser does not support speech recognition');
    }

    return () => {
      // Membatalkan rekaman jika komponen unmount
      if (recognition) {
        recognition.abort();
      }
    };
  }, [onVoiceInput, setStatus]);

  const toggleRecording = () => {
    if (isRecording && recognition) {
      recognition.stop();
    } else if (recognition) {
      recognition.start();
    }
  };

  return (
    <div className="voice-input">
      <button
        id="mic-button"
        className={`mic-button ${isRecording ? 'recording' : ''}`}
        onClick={toggleRecording}
      >
        <Mic />
      </button>
      <div id="status-text" className="status-text">
        {status}
      </div>
    </div>
  );
};

export default VoiceInput;
